/* line 11, scss/70-modules/globalsettingsmodule/_module.scss */
.m-global-settings {
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
}

/* line 18, scss/70-modules/globalsettingsmodule/_module.scss */
.m-global-settings .o-scrollbars::-webkit-scrollbar-track {
	background-color: rgba(var(--color-global-positive), 0.6);
}

/* line 22, scss/70-modules/globalsettingsmodule/_module.scss */
.m-global-settings .o-scrollbars::-webkit-scrollbar-thumb {
	background-color: rgba(var(--color-global-positive), 0.5);
}

/* line 26, scss/70-modules/globalsettingsmodule/_module.scss */
.m-global-settings .o-scrollbars::-webkit-scrollbar-thumb:hover {
	background-color: rgba(var(--color-global-positive), 0.7);
}

/* line 31, scss/70-modules/globalsettingsmodule/_module.scss */
.m-global-settings .global-settings-flag,
.m-global-settings .global-settings-flag-list {
	display: inline-block;
}

/* line 38, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__content {
	transform: translateX(100%);
	z-index: 999;
	text-align: left;
	padding: 10px 10px 10px 20px;
	right: 0;
	transition: transform .25s ease-in;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 320px;
	box-sizing: border-box;
	background-color: rgb(var(--color-global-secondary));
	display: flex;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__content {
	transform: translateX(-100%);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__content {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__content {
	padding: 10px 20px 10px 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__content {
	right: auto;
	left: 0;
}

/* line 53, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings-opened .global-settings__content {
	transform: translateX(0);
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
}

/* line 62, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__content .m-unit-converter:not(:last-child),
.global-settings__content .m-translation--list:not(:last-child),
.global-settings__content .m-currency-selector:not(:last-child) {
	margin-bottom: 20px;
}

/* line 68, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__content .translation__list-item:not(:last-child) {
	margin-bottom: 2px;
}

/* line 72, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__content-box-title {
	text-align: center;
	text-transform: uppercase;
}

/* line 78, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__content .m-currency-selector .currency-label {
	min-width: auto;
}

/* line 88, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__modules-container {
	padding: 0 10px 0 0;
	box-sizing: border-box;
	overflow-y: auto;
	width: 100%;
	max-height: 100%;
	margin: 10px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__modules-container {
	padding: 0 0 0 10px;
}

/* line 98, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle {
	cursor: pointer;
	text-transform: capitalize;
	vertical-align: middle;
}

/* line 103, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle--open {
	color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 999em) {
	/* line 103, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle--open {
		border-radius: 0;
		display: block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: rgb(var(--color-global-secondary));
		box-sizing: border-box;
		margin: 0 auto;
		border: 1px solid rgb(var(--color-global-border));
	}
	/* line 141, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle--open:hover {
		color: rgb(var(--color-global-positive));
		transition: background-color 0.25s linear;
		background-color: rgb(var(--color-dynamic-background-accent));
	}
}

/* line 152, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle--close {
	text-align: center;
	font-family: var(--font-family-headings);
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	padding-right: 20px;
	color: rgb(var(--color-global-positive));
	display: block;
	position: relative;
}

@media only screen and (max-width: 47.999em) {
	/* line 152, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle--close {
		font-size: 1.0519em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 152, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle--close {
		font-size: 1.14838em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 152, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle--close {
		font-size: 1.34em;
		line-height: 1.5;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__handle--close {
	padding-right: initial;
	padding-left: 20px;
}

/* line 160, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle--close:hover {
	color: rgb(var(--color-global-positive));
}

/* line 165, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle-open-text {
	color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 999em) {
	/* line 165, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle-open-text {
		display: none;
	}
}

/* line 172, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle-mobile-text {
	display: none;
	margin-top: -20px;
	text-transform: uppercase;
}

@media only screen and (max-width: 999em) {
	/* line 172, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-settings__handle-mobile-text {
		display: inline-block;
	}
}

/* line 182, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle-open-icon {
	font-size: 1em;
	color: rgb(var(--color-global-secondary));
}

/* line 654, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.global-settings__handle-open-icon:before {
	content: "\f159";
}

/* line 189, scss/70-modules/globalsettingsmodule/_module.scss */
.global-settings__handle-close-icon {
	top: 50%;
	transform: translate(-0, -50%);
	right: 0;
	font-size: 0.5em;
	vertical-align: middle;
	position: absolute;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .global-settings__handle-close-icon {
	right: auto;
	left: 0;
}

@media only screen and (max-width: 999em) {
	/* line 202, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-header--mobile-first .global-settings__handle--open,
	.paw-header--mobile-first .global-settings__handle--open {
		border: none;
		padding-top: 4px;
	}
	/* line 209, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-header--mobile-first .global-settings__handle-open-icon,
	.paw-header--mobile-first .global-settings__handle-open-icon {
		font-size: 1.167em;
	}
	/* line 215, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-header--mobile-first .global-settings__handle-open-icon,
	.paw-header--mobile-first .global-settings__handle-open-icon {
		display: initial;
	}
	/* line 219, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-header--mobile-first .m-global-settings,
	.paw-header--mobile-first .m-global-settings {
		padding: 0;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 225, scss/70-modules/globalsettingsmodule/_module.scss */
	.global-header--mobile-first .m-global-settings,
	.paw-header--mobile-first .m-global-settings {
		padding: 0 10px 0 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .global-header--mobile-first .m-global-settings, .is-rtl
	.paw-header--mobile-first .m-global-settings {
		padding: 0 10px 0 10px;
	}
}

/* line 231, scss/70-modules/globalsettingsmodule/_module.scss */
.paw-header--mobile-first .global-settings__handle--open {
	padding-top: 4px;
}

@media only screen and (min-width: null) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
	.m-global-settings {
		padding: 10px 20px;
		background: rgb(var(--color-global-widget2));
	}
	/* line 18, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
	.m-global-settings:hover {
		background: rgb(var(--color-global-primary));
	}
}

@media only screen and (max-width: 999em) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
	.m-global-settings {
		padding-right: 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-global-settings {
		padding-right: initial;
		padding-left: 10px;
	}
}

/* line 27, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
.m-global-settings .global-settings__handle--open.global-settings__handle {
	background-color: transparent;
}

/* line 30, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
.m-global-settings .global-settings__handle--open.global-settings__handle:hover {
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 36, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
.m-global-settings .global-settings-flag {
	filter: grayscale(1);
}

/* line 39, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
.m-global-settings .global-settings-flag:hover {
	filter: unset;
}

@media only screen and (min-width: 48em) {
	/* line 45, scss/80-themes/AffinityDefault/70-modules/globalsettingsmodule/module.scss */
	.global-settings__handle-open-icon {
		display: none;
	}
}

/*# sourceMappingURL=../../../../../true */